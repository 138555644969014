exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Toast__root___242sR {\n    background: #313131;\n    border-radius: 4px;\n    color: #fff;\n    display: flex;\n    align-items: center;\n    padding: 16px;\n    justify-content: space-between;\n    font-size: 14px;\n    position: relative;\n    box-shadow:\n        0 3px 5px -1px rgba(0, 0, 0, 0.2),\n        0 6px 10px 0 rgba(0, 0, 0, 0.14),\n        0 1px 18px 0 rgba(0, 0, 0, 0.12);\n}\n\n.Toast__action___FMxl_ {\n    margin-left: 16px;\n    background: none;\n    border: 0;\n    color: #4bcffa;\n    font-size: 14px;\n    cursor: pointer;\n    outline: none;\n    position: relative;\n    transition: opacity 0.15s ease-in-out;\n    opacity: 0.8;\n}\n\n.Toast__action___FMxl_:hover {\n    opacity: 1;\n}\n\n.Toast__action___FMxl_:active {\n    top: 1px;\n}\n\n.Toast__alert___3kHvI {\n    opacity: 0;\n    position: absolute;\n}\n\n.Toast__root--error___3uM5s {\n    background: #d32f2f;\n}\n\n.Toast__root--error___3uM5s .Toast__action___FMxl_ {\n    color: #fff;\n}", ""]);

// exports
exports.locals = {
	"root": "Toast__root___242sR",
	"action": "Toast__action___FMxl_",
	"alert": "Toast__alert___3kHvI",
	"root--error": "Toast__root--error___3uM5s"
};